class Auth {

    constructor(){
        this.Autenticated = false
        this.Uname = ""
    }

    login (cb) {
        this.Autenticated = true
       cb()
    }

    logout(cb) {
        this.Autenticated = false
        cb()
    }

    isAuthenticated (){
        return this.Autenticated
    }

}


export default new Auth();