import React,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Store from "./Store";
import CenteredGrid from './CenteredGrid';
import {Route, Switch ,withRouter} from "react-router-dom";
import Protected from './Protected'
import Products from "./Products"
import Header from "./Header"
import Sidebar from "./Sidebar"
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import Category from './Category';
import Orders from './Orders'

const drawerWidth = 170;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 2,
    textAlign:'right',
    writingDirection:'rtl'
  },
  toolbar: {
    height:'60px',
    top:'0px'
  }
});

class Container extends Component{
  constructor(props){
    super(props)
    this.state={
      selectedStore:""
    }
  }
  selectStore=(store)=>{
    this.setState({selectedStore:store})
  }
  
  render(){
    const { classes } = this.props;
    return (
      <div className={classes.root}>
              <Header/>
      <main className={classes.content} >
        <div className={classes.toolbar} />
        <Switch>
          <Route exact path="/" render={()=><CenteredGrid selectStore ={this.selectStore}/>}/>
          <Route exact path="/store/:id" render={()=><Store store={this.state.selectedStore} />}/>
          <Protected path="/מוצרים" component={Products}/>
          <Protected path="/קטגוריות" component={Category}/>
          <Protected path="/הזמנות" component={Orders}/>
        </Switch>
       </main>
        <Hidden xsDown implementation="css">
              <Drawer
              className={classes.drawer}
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
                anchor='right'
              >
       <Sidebar/>
              </Drawer>
            </Hidden> 
         </div>
    );
  }

}
Container.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Container));
