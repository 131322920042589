import React,{Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Dialog, DialogActions,Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Db from './Db'
import LoadingSpinner from './LoadingSpinner';
import TextField from '@material-ui/core/TextField';
const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },

  });

  const headcolumns=[
      {id:'orderId',label:"מס' הזמנה",},
      {id:'storeName',label:'שם הרשת'},
      {id:'branchName',label:'שם הסניף'},
      {id:'orderPrice',label:'עלות הזמנה כוללת'},
      {id:'orderTime',label:'תאריך'},
  ]

class Orders extends Component{
    
    state={
        orders:[],
        doneLoading:false,
        items:{orderItems:[]},
        showItems:false,
        ordersUnchanged:[],
        orderBy:'orderId',
        direction:'asc',
        stilLoading:true,
        dataToDisplay:[]
    }
    
    
    componentDidMount(){
        fetch(Db.OrdersQuerry,{
            method:'GET',
            headers:new Headers(),
            credentials: 'include',
        }).then(res=>{
            if(200===res.status)
                return res.json();
        }).then(data=>{
            let orderedData = data.reverse();
            this.setState({orders:orderedData,ordersUnchanged:orderedData,stilLoading:false,dataToDisplay:orderedData});
        })
    }
    showOrderItems(event,items,id){
        this.setState({items:items,showItems:true});
    }

    handleFilter = (event)=>{
        const {name,value}= event.target
        const filter= this.state.orders.filter(row=>row[name].startsWith(value) )
        this.setState({dataToDisplay:filter})
    }

    handleSorting= id=>{
        let {direction,dataToDisplay,orderBy} = this.state;
        orderBy = id;
        if(orderBy==='orderTime')
        {
            if(direction==='asc')
            {
                direction = 'desc';
                dataToDisplay.sort(function (a,b){return Date.parse(b[orderBy]) -Date.parse(a[orderBy])})
            }
            else
            {
                direction = 'asc';
                dataToDisplay.sort(function (a,b){return Date.parse(a[orderBy]) -Date.parse(b[orderBy])})
            }
        }
        else if(orderBy==='storeName' ||orderBy==='branchName')
        {
            if(direction==='asc')
            {
                direction = 'desc';
            //    (a.last_nom > b.last_nom) ? 1 : ((b.last_nom > a.last_nom) ? -1 : 0));
            dataToDisplay.sort( (a,b)=> a[orderBy] <b[orderBy]?1:((b[orderBy]<a[orderBy]?-1:0)))
            }
            else
            {
                direction = 'asc';
                dataToDisplay.sort( (a,b)=> a[orderBy] >b[orderBy]?1:((b[orderBy]>a[orderBy]?-1:0)))
                
            }
        }
        else
        {
            if(direction==='asc')
            {
                direction = 'desc';
                dataToDisplay.sort(function (a,b){return b[orderBy] -a[orderBy]})
            }
            else
            {
                direction = 'asc';
                dataToDisplay.sort(function (a,b){return a[orderBy] -b[orderBy]})
            }
    }
        this.setState({direction:direction,dataToDisplay:dataToDisplay,orderBy:orderBy})
        
    }

    render(){
        return(
            this.state.stilLoading?
            <LoadingSpinner/>
            :
            <div>
                <Typography variant="h6" style={{marginBottom:15}}>:פירוט הזמנות</Typography>
            <Paper className={styles.root}>
            <Table className={styles.table} dir="rtl">
                <TableHead>
                <TableRow>
                    {headcolumns.map(row=>{
                        return( <TableCell key = {row.id} align="right" sortDirection='asc'>
                            <TableSortLabel
                            active={row.id===this.state.orderBy}
                            direction={this.state.direction}
                            onClick={e=>this.handleSorting(row.id)}
                >{row.label}
                </TableSortLabel></TableCell>)
                    })}
                </TableRow>
                <TableRow>
                <TableCell align="right"></TableCell>
                    <TableCell align="right">
                    <TextField
                    id="standard-with-placeholder"
                    placeholder="חפש רשת..."
                    type = "text"
                    name = "storeName"
                    value = {this.state.Uname}
                    onChange = {this.handleFilter}
                    InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                    classes={styles.filled}
                  />
                    </TableCell>
                    <TableCell align="right">
                    <TextField
                    id="standard-with-placeholder"
                    placeholder="חפש סניף..."
                    type = "text"
                    name = "branchName"
                    value = {this.state.Uname}
                    onChange = {this.handleFilter}
                    InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                    classes={styles.filled}
                  />
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                
                </TableRow>
                </TableHead>
                <TableBody>
                {this.state.dataToDisplay.map(row => 
                   { 
                       let date = Date.parse(row.orderTime);
                       let d = new Date(date);
                    return(
                        <TableRow key={row.orderId} hover onClick= {(e)=>this.showOrderItems(e,row)}>
                        <TableCell align="right"><div>{row.orderId}</div></TableCell>
                        <TableCell align="right">{row.storeName}</TableCell>
                        <TableCell align="right">{row.branchName}</TableCell>
                        <TableCell align="right">{row.orderPrice}</TableCell>
                        <TableCell align="right">{d.toLocaleDateString('he-HE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' ,hourCycle:'h24', hour:'numeric',minute:'numeric'})}</TableCell>
                    </TableRow>
                    )}
                                        
                    )}
                </TableBody>
            </Table>
            </Paper>
            <Dialog open = {this.state.showItems} dir = "rtl" maxWidth = "lg">
                <DialogTitle id="form-dialog-title" > פירוט הזמנה מס': {this.state.items.orderId}</DialogTitle>
                <DialogContent>
                <Paper className={styles.root}>
                            <Table className={styles.table} dir="rtl">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="right">שם מוצר</TableCell>
                                    <TableCell align="right">כמות בהזמנה</TableCell>
                                    <TableCell align="right">מחיר</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.items.orderItems.map(row=>(
                                        <TableRow key={row.productId}>
                                            <TableCell align="right"><div>{row.productName}</div></TableCell>
                                            <TableCell align="right">{row.productQuantity}</TableCell>
                                            <TableCell align="right">{row.productCustomerPrice}</TableCell>
                                        
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>
                        <Typography variant = "h6">עלות הזמנה: {this.state.items.orderPrice} ש"ח</Typography>
                        
                        <Paper className={styles.root}>
                        <Typography variant = "h6">הערות להזמנה:</Typography>
                        <Typography variant = "body1">{this.state.items.orderDescription}</Typography>
                        </Paper>
                </DialogContent>
                <DialogActions>
                    <Button color = "primary" onClick={e=>this.setState({items:{orderItems:[]},showItems:false,id:''})}>סגור</Button>
                </DialogActions>
                </Dialog>
            </div>
                )
        }
    }


export default Orders;