import React, { Component } from 'react'
import Db from './Db'
import { CardContent, Typography, CardActions, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Snackbars from './Snackbars'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LoadingSpinner from './LoadingSpinner'
import {Link } from "react-router-dom";
const styles = {
    card: {
      minWidth: 275,
      
    },
}
class Category extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            categories:[],
            page:0,
            editCategoryName:false,
            doneLoading:false,
            openSnack:{open:false,Message:'',type:0}
        }
    }

    componentDidMount()
    {
        fetch(Db.CategoryQuerry,{
            method:'GET',
            headers:new Headers(),
            credentials: 'include',
        }).then(res =>
        {
            if(res.status === 200)
            {
                return res.json()
            }
        }).then(data =>
        {
            this.setState({categories:data,doneLoading:true})
        })
    }

    handleNameEdit = (event) =>
    {
        const {value} = event.target;
        const updatedCategory = this.state.categoryToEdit;
        updatedCategory.Name = value;
        this.setState({categoryToEdit:updatedCategory});
    }
    
    openSnackBar=(message,type)=>
    {
        const prevState = this.state;
        this.setState({...prevState,openSnack:{
            open:true,
            Message:message,
            type:type
        }})
    }

    closeSnackbar = ()=> {
      const prev = this.state.openSnack;
      this.setState({openSnack:{...prev,open:false}});
  }

    handleCategoryDelete = (category)=>
    {
        fetch(Db.CategoryQuerry,{
            method:'DELETE',
            headers:new Headers(),
            credentials: 'include',
            body:JSON.stringify({
                categoryId:category.ID
            })
        }).then(res => {
            if(200 === res.status)
            {
                let newPage = this.state.page;
                if((this.state.categories.length-1)%18===0)
                    newPage-=1;
                let updatedCategories = this.state.categories;
                updatedCategories = updatedCategories.filter(cat =>cat.ID !==category.ID);
                this.setState({categories:updatedCategories,page:newPage});
                this.openSnackBar('קטגוריה נמחקה בהצלחה',1);
            }
            else
                this.openSnackBar('קרתה שגיאה,נסה שנית',2);
        })
    }
    handleSubmit=()=>
    {
        fetch(Db.CategoryQuerry,{
            method:'PUT',
            headers:new Headers(),
            credentials: 'include',
            body:JSON.stringify({
                categoryName:this.state.categoryToEdit.Name,
                categoryId:this.state.categoryToEdit.ID,
                categoryRivhit:this.state.categoryToEdit.RivhitID,
            })
        }).then(res=>{
            if(200 === res.status)
            {
                this.setState({editCategoryName:false});
                this.openSnackBar('קטגוריה שונתה בהצלחה',1);
            }
            else
            this.openSnackBar('קרתה שגיאה,נסה שנית',2);
        })
    }
    handleEdit=(category)=>{
        let {editCategoryName} = this.state;
        editCategoryName= editCategoryName===true?this.state.categoryToEdit===category?false:true:true;
        this.setState({editCategoryName:editCategoryName,categoryToEdit:category})
        
    }
    render()
    {
         const card = {maxWidth:275}
        const display = this.state.categories.map(category=>{
            return(
               
                <Grid item xs={12} sm ={6} m={4} lg={3} xl={2} key = {category.ID}>
                <Card style={card}>
                    <CardContent>
                        {this.state.editCategoryName && this.state.categoryToEdit.ID ===category.ID?
                        <TextField dir='rtl'
                        value={this.state.categoryToEdit.Name}
                        onChange = {this.handleNameEdit}
                        name="Name"
                        onKeyPress = {event =>{
                            if(event.charCode === 13)
                                this.handleSubmit()
                        }}
                        ></TextField>
                        :
                        <Typography variant="h5" component="h2">{category.Name}</Typography>
                        }
                        <Typography variant="h6" component="h2">{category.ProductCount}:כמות</Typography>
                    </CardContent>
                    <CardActions>
                        <Link to ={'/מוצרים/id/'+category.ID}  style={{ textDecoration: 'none'}}>
                        <Button size = "small" color="primary">
                        הצג מוצרים
                            
                        </Button>
                        </Link>
                        <Button size = "small" color="primary" onClick = {this.handleCategoryDelete.bind(this,category)}>מחק</Button>
                        <Button size = "small" color="primary" onClick = {this.handleEdit.bind(this,category)}>{this.state.editCategoryName && this.state.categoryToEdit===category?'בטל':'ערוך'}</Button>
                    </CardActions>
                </Card>
                </Grid>
               
            )
        })
        
        return(
            this.state.doneLoading?
            <div>
                <div style={{textAlign:'center'}}>
                    <Button onClick = {()=>{const page = this.state.page;this.setState({page:page+1})}}
                        disabled = {(this.state.page*18)+18 >= this.state.categories.length}
                        color="primary"
                    > 
                    <ArrowBackIosIcon/>
                    הבא
                    </Button>
                    <Button onClick = {()=>{const page = this.state.page;this.setState({page:page-1})}}
                        disabled = {this.state.page===0}
                        color="primary"
                    >
                    הקודם
                    <ArrowForwardIosIcon/>
                    </Button>
                </div>
                <Grid container direction='row-reverse' spacing={24}>
                    {display.slice(this.state.page*18,(this.state.page*18)+18)}
                </Grid>
                <Snackbars open={this.state.openSnack} closeHandler = {this.closeSnackbar}/>
            </div>
             :
             <LoadingSpinner/>
        )
    }

}
Category.propTypes = {
    classes: PropTypes.object.isRequired
  };

export default withStyles(styles)(Category);