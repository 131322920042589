import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DescriptionIcon from '@material-ui/icons/Description';
import HomeIcon from '@material-ui/icons/Home';
import CategoryIcon from '@material-ui/icons/Category';
import ReceiptIcon from '@material-ui/icons/Receipt';

import { withRouter,Link } from "react-router-dom";

const drawerWidth = 170;

const styles = theme => ({
  root: {
    display: 'flex',
  },

  drawer: {
    [theme.breakpoints.up('sm')]:{
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,

  },
  grow: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  logo: {
    height:60,
    width:120,
    anchor:"right"
  },
  toolbar: theme.mixins.toolbar,
});

class Sidebar extends Component {
  render(){
    const { classes } = this.props;
  return (
    <div>
      <div className={classes.toolbar} />
      <List>
        {[{text:'דף הבית',icon:<HomeIcon />},{text:'קטגוריות',icon:<CategoryIcon/>},{text:'מוצרים',icon:<ReceiptIcon/>},{text:'הזמנות',icon: <DescriptionIcon />}].map((link, index) => (
          <Link to ={link.text==='דף הבית'?'/':'/'+link.text} key={index} style={{ textDecoration: 'none'}}>
            <ListItem button  >
              <ListItemText primary={link.text} style={{textAlign:"right"}}></ListItemText>
              <ListItemIcon>{link.icon}</ListItemIcon>
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
    );
  }
}
    Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withRouter(Sidebar));