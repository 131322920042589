import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Redirect } from "react-router-dom";
import Db from './Db'
const styles = {
  card: {
    maxWidth: 250,
    flexbasis:'unset'
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
  },
};

class ImgMediaCard extends Component {
    constructor(props){
        super(props)
        this.state={
            redirect:false
        }
    }
    buttonClick= store=>{
        this.props.selectStore(store) 
        this.setState({redirect:true, to:store.storeId})
      } 
      render(){
  const { classes } = this.props;
  return (
      this.state.redirect?
        <Redirect
        to = {{
            pathname:"store/"+this.state.to,
            state:{
                from:this.props.location
            }
        }}
        />:
    <Card className={classes.card} >
      <CardActionArea onClick = {this.buttonClick.bind(this, this.props.store)}>
        <CardMedia
          component="img"
          alt={this.props.store.storeName}
          className={classes.media}
          height="100"
          image={`${Db.storeImagePrefix}${this.props.store.storeImg}`}
          title={this.props.store.storeName}
        />
        <CardContent >
          <Typography gutterBottom variant="h5" component="h2" className={classes.media}>
            {this.props.store.storeName}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
}

ImgMediaCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImgMediaCard);