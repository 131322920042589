import Db from './Db'
import React,{Component} from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LoadingSpinner from './LoadingSpinner'
import { Dialog, DialogActions } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TablePagination from '@material-ui/core/TablePagination';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Snackbars from './Snackbars'
const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  filled:{
    right:'-58px',
    position:'absolute'
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '90vh',
},
});

class Products extends Component{
    constructor(props){
        super(props)
        this.state={
          newImage:'',
          categories:[],
          data:[],
          dataToDisplay:[],
          edit:{
            needsEdit:false,
            element:0,
            productInfo:{}
          },
          selected:[],
          page: 0,
          rowsPerPage: 10,
          stilLoading:true,
          showImages:false,
          categoryFilter:0,
          openSnack:{open:false,Message:'',type:0}
        }
    }

    componentDidMount ()
    {

      let param = this.props.info.location.pathname.split('/',4)[3];
      fetch(Db.CategoryQuerry,{
        method:'GET',
        headers:new Headers(),
        credentials: 'include'
      }).then(res=>{
        if(200===res.status)
          return res.json()
      }).then(cat=>{
        this.setState({categories:cat,stilLoading:false})
      })
      fetch(Db.productQuerry,{
        method:'GET',
        headers:new Headers(),
        credentials: 'include'
      }).then(res =>{
        if (200 ===res.status)
          return res.json()
      }).then(data=>{
        let categoryFilterData;
        if(param>0)
          categoryFilterData = data.filter(row=>row.productCategory===parseInt(param))
        else
        categoryFilterData = data;
        this.setState({data:data,dataToDisplay:categoryFilterData,categoryFilter:param>0?param:0})
      })
    }
    handleNameFilter = (event)=>{
        const {value} = event.target
        const filter= this.state.data.filter(row=>row.productName.toLowerCase().startsWith(value) )
        this.setState({dataToDisplay:filter})
    }
    handleBarcodeFilter = (event)=>{
      const {value}= event.target
      const filter= this.state.data.filter(row=>row.productBarcode.startsWith(value) )
      this.setState({dataToDisplay:filter})
  }

  handleFile = event => {
    this.setState({...this.state.edit,newImage:event.target.files})
  }

    handleEdit = (product,event)=>{
      const{type} = event.target
      if("checkbox" === type)
      {
        this.handleClick(event,product)
        return;
      }
      this.setState({
        edit:
        {
          productInfo:product,
          needsEdit:true,
          element:product.productId
      },
     // [value]:value
    })
    
    }

    submitChange= (row) => {
      //TODO:handle DB request.
      let form = new FormData();
      const rowToChange = this.state.edit
      let updatedData = this.state.data
      const{productInfo} = rowToChange;
      form.append("file", this.state.newImage[0]);
      form.append('product',JSON.stringify(productInfo));
      fetch(Db.productQuerry,{
        method:'POST',
        headers:new Headers(),
        credentials: 'include',
        body:form
      }).then(res =>{
        if(200 === res.status){
          return res.json();
        }
        else
        {
          this.openSnackBar('קרתה שגיאה,נסה שנית',2);
          return Promise.reject();
        }
      }).then(data=>{
          //need to get product Id in case of insertion, and need to check for returned image name.
          if(rowToChange.element===undefined)
          {
            updatedData.push(productInfo)
            this.openSnackBar('מוצר התווסף בהצלחה',1);
          }
            else
            {
              let current = updatedData.find(obj=>obj.productId===rowToChange.element)
              let index = updatedData.indexOf(current)
             updatedData[index] = productInfo
             this.openSnackBar('פרטי המוצר שונו בהצלחה',1);
            } 
          this.setState({...this.state,
            newImage:'',
            categories:this.state.categories,
            edit:
            {
              productInfo:{},
              needsEdit:false,
              element:0
          },})
      })
    }

    openSnackBar=(message,type)=>
    {
        const prevState = this.state;
        this.setState({...prevState,openSnack:{
            open:true,
            Message:message,
            type:type
        }})
    }

    closeSnackbar = ()=> {
      const prev = this.state.openSnack;
      this.setState({openSnack:{...prev,open:false}});
  }

   
    handleProductChange = (event)=>{
      let prevState = Object.assign({},this.state);
      const {name,value}=event.target
      this.setState({...prevState,edit:{...prevState.edit,productInfo:{...prevState.edit.productInfo,[name]:value}}})
    }
    handleChangePage = (event, page) => {
      this.setState({ page });
    };
  
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };

    handleClick = (event, product) => {
      const { selected } = this.state;
      const selectedIndex = selected.indexOf(product);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, product);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
      this.setState({ selected: newSelected });
      }

      handleImageCheckBox= event=>{
        let checkBoxNew = this.state.showImages;
        this.setState({...this.state, showImages:!checkBoxNew})
      }

      handleProductFilter = event=>{
        const {value} = event.target;
        let categoryFilter;
        if(0 === value)
        {
          categoryFilter = this.state.data;
        }
        else
          categoryFilter = this.state.data.filter(row=>row.productCategory===value)
         this.setState({dataToDisplay:categoryFilter,categoryFilter:value});
      }

    isSelected = row => this.state.selected.indexOf(row) !== -1;

    createExcelQuote = ()=>
    {
      fetch(Db.QuoteQuerry,{
        method:'POST',
        headers:new Headers(),
        credentials: 'include',
        body:JSON.stringify(this.state.selected)
      }).then(res=>{
        if(200===res.status)
        {
          return res.blob();
        }
        else
        {
          this.openSnackBar('שגיאה ביצירת הצעת מחיר!',2);
          return Promise.reject();
        }
      }).then(blob=>{
        var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download = "Quotation.xlsx";
            document.body.appendChild(a);
            a.click();    
            a.remove(); 
            this.openSnackBar('הצעת מחיר נוצרה בהצלחה',1);
      })
    }

    render(){
        const { classes } = this.props;
  return (
    
      this.state.stilLoading?
      <LoadingSpinner/>
      :
      <div>
        <div style={{marginBottom:15}}>
        <Button 
          style={{left:24,position:'absolute',marginBottom:5}}
          color="primary"
          variant="contained"
          onClick = {this.createExcelQuote}
          >{this.state.selected.length}-הצעת מחיר
          </Button>
          <Button
            color="primary"
            variant = "contained"
            onClick={e=>this.setState({...this.state,edit:{needsEdit:true,productInfo:{}}})}
          >הוסף מוצר</Button>
        </div>
        <Paper className={classes.root} style = {{marginTop:5}}>

          <Table className={classes.table} dir="rtl">
            <TableHead>
              <TableRow key={-1}>
                <TableCell></TableCell>
                <TableCell padding="checkbox" align = "right" dir = "ltr">
                  <Switch
                    checked={this.state.showImages} 
                    onChange = {this.handleImageCheckBox}
                    color = "primary"
                  />הצג תמונות
                </TableCell>
                <TableCell align="right">שם המוצר</TableCell>
                <TableCell align="right">ברקוד</TableCell>
                <TableCell align="right">קטגוריה</TableCell>
                <TableCell align="right">מחיר ללקוח</TableCell>
                <TableCell align="right">מחיר עלות</TableCell>
                <TableCell align="right">כמות במלאי</TableCell>
                <TableCell align="right">יחידות בקרטון</TableCell>
              </TableRow>
              <TableRow  key={0}>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-with-placeholder"
                    placeholder=" חפש שם..."
                    type = "text"
                    name = "Term"
                    value = {this.state.Uname}
                    onChange = {this.handleNameFilter}
                    InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                    classes={styles.filled}
                  />
                </TableCell>
                <TableCell align="right">
                  <TextField
                    id="standard-with-placeholder"
                    placeholder="חפש ברקוד..."
                    type = "text"
                    name = "Term"
                    value = {this.state.Uname}
                    onChange = {this.handleBarcodeFilter}
                    InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                    classes={styles.filled}
                  />
                </TableCell>
                <TableCell align="right">
                  <Select dir="rtl"
                    value={this.state.categoryFilter}
                    onChange={this.handleProductFilter}
                    displayEmpty
                    autoWidth
                    style = {{minWidth:150}}
                  >
                    <MenuItem value={0}>
                      <em>כל הקטגוריות</em>
                    </MenuItem>
                    {this.state.categories.map(category=><MenuItem key ={category.ID} value={category.ID}>{category.Name}</MenuItem>)}
                  </Select>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataToDisplay.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map(row => 
                {
                  const isSelected = this.isSelected(row);
                  return(
                <TableRow key={row.productId} name={row.productId} hover onClick={(e)=>this.handleEdit(row,e)}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected} color = "primary"/>
                  </TableCell>
                  <TableCell align = "right">
                  {
                    this.state.showImages?
                    <img 
                      src = {`${Db.imagePrefix}${row.productImg} `}
                      style = {{height:100,width:150}}
                      alt="תמונת מוצר"
                    ></img>:
                    null
                  }
                    </TableCell>
                  <TableCell component="th" scope="row" align="right">{row.productName}</TableCell>
                  <TableCell align="right">{row.productBarcode}</TableCell>
                  <TableCell align="right">{(this.state.categories.find((category) =>row.productCategory === category.ID)).Name}</TableCell>
                  <TableCell align="right">{row.productCustomer}</TableCell>
                  <TableCell align="right">{row.productCost}</TableCell>
                  <TableCell align="right">{row.productQuantity}</TableCell>
                  <TableCell align="right">{row.productItemInBox}</TableCell>
                </TableRow>
              )})}
            </TableBody>
          </Table>
          <TablePagination style = {{paddingRight:'41%'}}
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={this.state.dataToDisplay.length}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          nextIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          backIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          labelRowsPerPage = 'מספר מוצרים בעמוד'
        />
        </Paper>
        <Dialog
        classes={{ paper: classes.dialogPaper }}
        open = {this.state.edit.needsEdit}
        aria-labelledby="form-dialog-title"
        maxWidth={"xs"}
        fullWidth={true}
        style = {{textAlign:'end'}}
        
        
        >
        <DialogTitle id="form-dialog-title">עריכת מוצר</DialogTitle>
        <DialogContent>
        <TextField
          variant="outlined"
          inputProps = {{style:{direction:"rtl"}}}
          autoFocus
          name="productName"
          label="שם המוצר"
          type="text"
          value = {this.state.edit.productInfo.productName|| ''}
          onChange = {this.handleProductChange}
           margin ="normal"
        />
        <br/>
        <TextField
          variant="outlined"
          inputProps = {{style:{direction:"rtl"}}}
          autoFocus
          name="productBarcode"
          label="ברקוד"
          type="text"
          value = {this.state.edit.productInfo.productBarcode|| '' }
          onChange = {this.handleProductChange}
          margin ="normal"
      />  
      <br/>
        <TextField
          variant="outlined"
          inputProps = {{style:{direction:"rtl"}}}
          autoFocus
          name="productCustomer"
          label="מחיר ללקוח"
          type="text"
          value = {this.state.edit.productInfo.productCustomer|| '' }
          onChange = {this.handleProductChange}
          margin ="normal"
      />
      <br/>
        <TextField
          variant="outlined"
          inputProps = {{style:{direction:"rtl"}}}
          name="productCost"
          label="מחיר עלות"
          type="text"
          value = {this.state.edit.productInfo.productCost|| '' }
          onChange = {this.handleProductChange}
          margin ="normal"
      /> 
      <br/>
        <TextField
          variant="outlined"
          inputProps = {{style:{direction:"rtl"}}}
          name="productQuantity"
          label="כמות במלאי"
          type="text"
          value = {this.state.edit.productInfo.productQuantity|| '' }
          onChange = {this.handleProductChange}
           margin ="normal"
      /> 
            <br/>
        <TextField
          variant="outlined"
          inputProps = {{style:{direction:"rtl"}}}
          name="productItemInBox"
          label="יחידות בקרטון"
          type="text"
          value = {this.state.edit.productInfo.productItemInBox || ''}
          onChange = {this.handleProductChange}
          margin ="normal"
      /> 
      <br/>
      
      <form autoComplete="off">
        <FormControl >
          <InputLabel htmlFor="demo-controlled-open-select">קטגוריה</InputLabel>
          <Select dir="rtl"
            open={this.state.open}
            value={this.state.edit.productInfo.productCategory===undefined?0:this.state.edit.productInfo.productCategory}
            onChange={this.handleProductChange}
            inputProps={{
              name: 'productCategory',
              id: 'demo-controlled-open-select',
            }}
            autoWidth
            style = {{minWidth:150}}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {this.state.categories.map(category=><MenuItem  key={category.ID} value={category.ID}>{category.Name}</MenuItem>)}
          </Select>
        </FormControl>
      </form>
      {this.state.edit.productInfo.productImg!==undefined?
      <div style={{textAlign:'center',padding:10}}>
          <img 
            src = {`${Db.imagePrefix}${this.state.edit.productInfo.productImg} `}
            style = {{height:130,width:180,align:'center'}}
            alt="תמונת מוצר"
          ></img>
      </div>
      :null
    }
    <TextField dir="ltr"
      margin="dense"
      name="storeImg"
      label="תמונת מוצר"
      type="file"
      onChange = {this.handleFile}
      InputLabelProps= {{style:{right:'0px',position:'absolute',textAlign:'right'}}}
    />  
        </DialogContent>
            <DialogActions dir = "rtl" >
              <Button onClick={()=>this.setState({...this.state,edit:{needsEdit:false,productInfo:{}}})} color="primary">בטל שינוים</Button>
              <Button onClick={this.submitChange} color="primary">שמור</Button>
            </DialogActions>
        </Dialog>
        <Snackbars open={this.state.openSnack} closeHandler = {this.closeSnackbar}/>
    </div>
  )};
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Products);