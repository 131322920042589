import React from 'react'

function LoadingSpinner(){
    return(
        <div style={{position:'relative',textAlign:'center'}}>
          <img  alt="loading..." src = 'https://media1.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=790b76115ced674f6153344e599b33e8&rid=giphy.gif'></img>
        </div>
    )
}

export default LoadingSpinner;