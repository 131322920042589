import React from "react"
import { Route, Redirect } from "react-router-dom";
import auth from "./auth"
const Protected = ({
    name,
    component:Component,
     ...rest})=> {
            return(
                <Route 
                {...rest} 
                render = {props => {
                if(auth.isAuthenticated()){
                    return (<Component name={name} info={rest} />)
                } else{
                    return (
                        <Redirect
                        to = {{
                            pathname:"/login",
                            state:{
                                from:props.location
                            }
                        }}
                        />
                    )
                }
                }}/>
            )
}
export default Protected