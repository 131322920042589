import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = theme => ({
  close: {
    padding: theme.spacing.unit / 2,
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

class Snackbars extends React.Component {
  constructor(props){
 super(props);
    this.state = {
    open: false,
  };
}
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.props.closeHandler();
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={this.handleClose}
          open={this.props.open.open}
          autoHideDuration={3000}
        >
          <SnackbarContent
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
          onClose={this.handleClose}
          className={this.props.open.type===1?classes.success:classes.error}
          message={this.props.open.Message}
        />
        </Snackbar>
      </div>
    );
  }
}

Snackbars.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Snackbars);