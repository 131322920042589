import React, {Component} from "react"
import auth from "./auth"
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Snackbars from './Snackbars'
import DialogTitle from '@material-ui/core/DialogTitle';
import {Redirect } from "react-router-dom";
import Db from "./Db"

class Login extends Component{
    constructor(){
        super()
        this.state={
            Uname:"",
            Password:"",
            error:false,
            openSnack:{open:false,Message:'',type:0},
        }
    }
    
    componentDidMount(){
        fetch(Db.SessionQuery,{
            method:'GET',
            headers:new Headers(),
      credentials: 'include',
  }).then(res=>{
      if(200 === res.status){
          return res.json()
      }
      else if (401 === res.status)
      {
          return;
      }
  }).catch(err=>{
      console.log(err);
  })
  .then(data=>{
      if(undefined !==data){
        auth.login(()=>{
            this.props.history.push(this.props.location.state.from.pathname)
        })
       // this.setState({Uname:Username})
      }
      return
  })
    }

    handleChange = event =>{
        const {name,value} = event.target
        this.setState({[name]:value})
    }

    openSnackBar=(message,type)=>
    {
        const prevState = this.state;
        this.setState({...prevState,openSnack:{
            open:true,
            Message:message,
            type:type
        }})
    }
    closeSnackbar = ()=> {
        const prev = this.state.openSnack;
        this.setState({openSnack:{...prev,open:false}});
    }

    handleSubmit =(event) =>{
        fetch(Db.LoginQuery,{
            method:'POST',
            headers: new Headers(),
            credentials: 'include',
            body:JSON.stringify({
                Username:this.state.Uname,
                Password:this.state.Password
            }),
        }).then(res=>{
            if(200 === res.status){
                auth.login(()=>{
                    this.props.history.push(this.props.location.state.from.pathname)
                })
                return res.json()
            }else{
                this.setState({error:true})
                this.openSnackBar('פרטי כניסה שגויים',2);
             }
        }).then(data =>{
            //cookie.save('x-access-token', data['x-access-token']);
        })
    }

    render(){
       if(auth.Autenticated){
           return(
               <Redirect
               to={{
                   pathname: "/",
                   state:{
                       from:this.props.location
                   }
               }}/>
           )
       }
        return (
            <div  position = "relative" >
                <Dialog
                    open={!auth.Autenticated}
                    fullWidth={true}
                    maxWidth={"xs"}
                    style={{textAlign:'right'}}
                >
                <DialogTitle id="form-dialog-title">התחברות</DialogTitle>
                <DialogContent style = {{paddingRight:80}}>
                    <TextField
                    required
                    variant="outlined"
                     inputProps = {{style:{direction:"rtl"}}}
                       
                        label="שם משתמש"
                        type = "text"
                        name = "Uname"
                        value = {this.state.Uname}
                        onChange = {this.handleChange}
                        margin ="normal"
                   
                    />
                    <br/>
                    <TextField  
                    error = {this.state.error}
                    required
                    variant="outlined"
                     inputProps = {{style:{direction:"rtl"}}}              
                        label="סיסמא"
                        type = "password"
                        name = "Password"
                        value = {this.state.Password}
                        onChange = {this.handleChange}
                        margin ="normal"
                        onKeyPress = {event =>{
                            if(event.charCode === 13)
                                this.handleSubmit()
                        }}
                    />
                </DialogContent>
            <DialogActions dir="rtl">
                <Button type = "submit" onClick = {this.handleSubmit} color="primary">
                התחבר
                </Button>
            </DialogActions>
            </Dialog>
            <div dir="ltr">
                <Snackbars open={this.state.openSnack} closeHandler = {this.closeSnackbar}/>
            </div>
            </div>
        )
    }
        
}

export default Login