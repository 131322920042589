import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Db from './Db';
import SaveIcon from '@material-ui/icons/Save';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TablePagination from '@material-ui/core/TablePagination';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogActions, DialogContent } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Snackbars from './Snackbars';
const styles = (theme) => ({
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '90vh',
  },
});

function Transition(props) {
  return <Slide direction='up' {...props} />;
}

class StoreProduct extends Component {
  state = {
    storeId: this.props.storeId,
    openSnack: { open: false, Message: '', type: 0 },
    products: [],
    page: 0,
    rowsPerPage: 10,
    stilLoading: 0,
    showImages: false,
    categoryFilter: 0,
    categories: [],
    selected: [],
    originalSelected: [],
    changes: [],
    changeObject: {
      productName: '',
      productBarcode: '',
      priceForFinalCustomer: '',
      storeSku: '',
      saleFlag: '',
      saleStartDate: '',
      saleEndDate: '',
      newProductFlag: '',
      newProductEndDate: '',
      priceForStore: '',
      salePrice: '',
    },
    changeProductFlag: false,
  };

  handleClose = () => {
    this.setState({ selected: this.state.originalSelected });
    this.props.close();
  };
  handleNameFilter = (event) => {
    const { value } = event.target;
    const filter = this.state.products.filter((row) =>
      row.productName.toLowerCase().startsWith(value)
    );
    this.setState({ dataToDisplay: filter });
  };
  handleBarcodeFilter = (event) => {
    const { value } = event.target;
    const filter = this.state.products.filter((row) =>
      row.productBarcode.startsWith(value)
    );
    this.setState({ dataToDisplay: filter });
  };
  handleProductFilter = (event) => {
    const { value } = event.target;
    let categoryFilter;
    if (0 === value) {
      categoryFilter = this.state.products;
    } else
      categoryFilter = this.state.products.filter(
        (row) => row.productCategory === value
      );
    this.setState({ dataToDisplay: categoryFilter, categoryFilter: value });
  };

  handleSave = () => {
    let added = this.state.selected;
    let removed = this.state.originalSelected;
    added = added.filter(
      (product) => !this.state.originalSelected.includes(product)
    );
    removed = removed.filter(
      (product) => !this.state.selected.includes(product)
    );
    let JsonAdded = [];
    added.forEach((product) =>
      JsonAdded.push({
        productId: product.productId,
        storeId: this.state.storeId,
      })
    );
    let JsonRemoved = [];
    removed.forEach((product) =>
      JsonRemoved.push({
        productId: product.productId,
        storeId: this.state.storeId,
      })
    );
    fetch(Db.ProductToStoreQuerry, {
      method: 'POST',
      headers: new Headers(),
      credentials: 'include',
      body: JSON.stringify({
        added: JsonAdded,
        removed: JsonRemoved,
      }),
    })
      .then((res) => {
        if (200 === res.status) {
          return res.json();
        }
      })
      .then((data) => {
        let newOrderedProducts = this.state.products;
        newOrderedProducts.forEach((product) => {
          data.forEach((res) => {
            if (product.productId === res.productId) {
              product.priceForFinalCustomer = res.priceForFinalCustomer;
              product.priceForStore = res.priceForStore;
            }
          });
        });
        newOrderedProducts.forEach((product) => {
          if (this.state.selected.includes(product))
            product.storeId = this.state.storeId;
          else product.storeId = 0;
        });
        this.setState({
          products: newOrderedProducts,
          page: 0,
          rowsPerPage: 10,
          originalSelected: this.state.selected,
        });
        this.props.close();
      });
  };
  componentDidMount() {
    fetch(Db.storeProductQuerry + this.state.storeId, {
      method: 'GET',
      headers: new Headers(),
      credentials: 'include',
    })
      .then((res) => {
        if (200 === res.status) {
          return res.json();
        }
      })
      .then((data) => {
        let stil = this.state.stilLoading + 1;
        let selectedProducts = data.filter(
          (product) => product.storeId === this.state.storeId
        );
        this.setState({
          originalSelected: selectedProducts,
          selected: selectedProducts,
          products: data,
          stilLoading: stil,
          dataToDisplay: data,
        });
      });
    fetch(Db.CategoryQuerry, {
      method: 'GET',
      headers: new Headers(),
      credentials: 'include',
    })
      .then((res) => {
        if (200 === res.status) return res.json();
      })
      .then((cat) => {
        let stil = this.state.stilLoading + 1;
        this.setState({ categories: cat, stilLoading: stil });
      });
  }

  handleClick = (event, product) => {
    const { type, id } = event.target;
    if ('checkbox' !== type) {
      let date = Date.parse(product.saleStartDate);
      let d = new Date(date);
      product.saleStartDate = `${d.getFullYear()}-${
        d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
      }-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`;
      this.setState({
        changeObject: Object.assign({}, product),
        changeProductFlag: true,
      });
      return;
    }
    if ('checkbox' === type) {
      if (id !== 'check') return;
    }
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(product);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, product);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleProductChange = (event) => {
    let prevState = this.state;
    const { name, value } = event.target;
    this.setState({
      ...prevState,
      changeObject: { ...prevState.changeObject, [name]: value },
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected(row) {
    return this.state.selected.indexOf(row) !== -1;
  }

  handleImageCheckBox = (event) => {
    let checkBoxNew = this.state.showImages;
    this.setState({ ...this.state, showImages: !checkBoxNew });
  };

  storeProductSave = (event) => {
    const {
      productBarcode,
      productCategory,
      productName,
      productImg,
      ...rest
    } = this.state.changeObject;
    rest.saleFlag = rest.saleFlag ? 1 : 0;
    rest.newProductFlag = rest.newProductFlag ? 1 : 0;
    fetch(Db.ProductToStoreQuerry, {
      method: 'PUT',
      headers: new Headers(),
      credentials: 'include',
      body: JSON.stringify({
        ...rest,
      }),
    }).then((res) => {
      if (200 === res.status) {
        let currProduct = this.state.products.find(
          (product) => product.productId === rest.productId
        );
        let index = this.state.products.indexOf(currProduct);
        const prevState = this.state.products;
        prevState[index] = this.state.changeObject;
        index = this.state.selected.indexOf(currProduct);
        const prevSelected = this.state.selected;
        prevSelected[index] = this.state.changeObject;
        index = this.state.dataToDisplay.indexOf(currProduct);
        const display = this.state.dataToDisplay;
        display[index] = this.state.changeObject;
        this.setState({
          changeObject: {
            productName: '',
            productBarcode: '',
            priceForFinalCustomer: '',
            storeSku: '',
            saleFlag: '',
            saleStartDate: '',
            saleEndDate: '',
            newProductFlag: '',
            newProductEndDate: '',
            priceForStore: '',
            salePrice: '',
          },
          changeProductFlag: false,
          products: prevState,
          selected: prevSelected,
          dataToDisplay: display,
        });
        this.isSelected(currProduct);
        this.openSnackBar('פרטי מוצר שונו בהצלחה', 1);
      } else {
        this.openSnackBar('קרתה שגיאה!נסה שנית', 2);
      }
    });
  };

  changeDate = (event) => {
    const { id, value } = event.target;
    this.setState({
      ...this.state,
      changeObject: { ...this.state.changeObject, [id]: value },
    });
  };

  openSnackBar = (message, type) => {
    const prevState = this.state;
    this.setState({
      ...prevState,
      openSnack: {
        open: true,
        Message: message,
        type: type,
      },
    });
  };
  closeSnackbar = () => {
    const prev = this.state.openSnack;
    this.setState({ openSnack: { ...prev, open: false } });
  };

  handleCheckbox = (event) => {
    const { name, checked } = event.target;
    let prevState = this.state.changeObject;
    prevState[name] = checked === true ? 1 : 0;
    this.setState({ changeObject: prevState });
  };

  render() {
    return this.state.stilLoading !== 2 ? null : (
      <Dialog
        dir='rtl'
        fullScreen
        open={this.props.open}
        onClose={this.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={styles.appBar}>
          <Toolbar>
            <IconButton
              color='inherit'
              onClick={this.handleClose}
              aria-label='Close'
            >
              <CloseIcon />
            </IconButton>
            <Typography variant='h6' color='inherit' className={styles.flex}>
              ניהול מוצרים לרשת {this.props.name}
            </Typography>
            <Button
              color='inherit'
              onClick={this.handleSave}
              style={{ left: 24, position: 'absolute' }}
            >
              <SaveIcon />
              שמור שינויים
            </Button>
          </Toolbar>
        </AppBar>
        <Paper style={{ marginTop: 100, marginLeft: 5, marginRight: 5 }}>
          <Table className={styles.table} dir='rtl'>
            <TableHead>
              <TableRow key={-1}>
                <TableCell></TableCell>
                <TableCell padding='checkbox' align='right' dir='ltr'>
                  <Switch
                    checked={this.state.showImages}
                    onChange={this.handleImageCheckBox}
                    color='primary'
                  />
                  הצג תמונות
                </TableCell>
                <TableCell align='right'>שם המוצר</TableCell>
                <TableCell align='right'>ברקוד</TableCell>
                <TableCell align='right'>קטגוריה</TableCell>
                <TableCell align='right'>מחיר לרשת</TableCell>
                <TableCell align='right'>מחיר לצרכן</TableCell>
                <TableCell align='right'>מבצע</TableCell>
                <TableCell align='right'>מחיר מבצע</TableCell>
                <TableCell align='right'>מוצר חדש</TableCell>
                <TableCell align='right'>מק"ט רשת</TableCell>
              </TableRow>
              <TableRow key={0}>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell align='right'>
                  <TextField
                    id='standard-with-placeholder'
                    placeholder=' חפש שם...'
                    type='text'
                    name='Term'
                    value={this.state.Uname}
                    onChange={this.handleNameFilter}
                    InputLabelProps={{
                      style: { right: '0px', position: 'absolute' },
                    }}
                    classes={styles.filled}
                  />
                </TableCell>
                <TableCell align='right'>
                  <TextField
                    id='standard-with-placeholder'
                    placeholder='חפש ברקוד...'
                    type='text'
                    name='Term'
                    value={this.state.Uname}
                    onChange={this.handleBarcodeFilter}
                    InputLabelProps={{
                      style: { right: '0px', position: 'absolute' },
                    }}
                    classes={styles.filled}
                  />
                </TableCell>
                <TableCell align='right'>
                  <Select
                    dir='rtl'
                    value={this.state.categoryFilter}
                    onChange={this.handleProductFilter}
                    displayEmpty
                    autoWidth
                    style={{ minWidth: 150 }}
                  >
                    <MenuItem value={0}>
                      <em>כל הקטגוריות</em>
                    </MenuItem>
                    {this.state.categories.map((category) => (
                      <MenuItem key={category.ID} value={category.ID}>
                        {category.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.dataToDisplay
                .sort(function (a, b) {
                  return b.storeId - a.storeId;
                })
                .slice(
                  this.state.page * this.state.rowsPerPage,
                  this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                )
                .map((row) => {
                  const isSelected = this.isSelected(row);
                  return (
                    <TableRow
                      key={row.productId}
                      name={row.productId}
                      hover
                      onClick={(e) => this.handleClick(e, row)}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          id='check'
                          checked={isSelected}
                          color='primary'
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {this.state.showImages ? (
                          <img
                            src={`${Db.imagePrefix}${row.productImg} `}
                            style={{ height: 100, width: 150 }}
                            alt='תמונת מוצר'
                          ></img>
                        ) : null}
                      </TableCell>
                      <TableCell component='th' scope='row' align='right'>
                        {row.productName}
                      </TableCell>
                      <TableCell align='right'>{row.productBarcode}</TableCell>
                      <TableCell align='right'>
                        {
                          this.state.categories.find(
                            (category) => row.productCategory === category.ID
                          ).Name
                        }
                      </TableCell>

                      <TableCell align='right'>
                        {row.storeId === 0 ? '-' : row.priceForStore}
                      </TableCell>
                      <TableCell align='right'>
                        {row.storeId === 0 ? '-' : row.priceForFinalCustomer}
                      </TableCell>
                      <TableCell padding='checkbox' align='right' dir='ltr'>
                        <Switch
                          checked={row.saleFlag === 1 ? true : false}
                          color='primary'
                        />
                      </TableCell>
                      {
                        <TableCell align='right'>
                          {row.saleFlag ? row.salePrice : ''}
                        </TableCell>
                      }
                      <TableCell padding='checkbox' align='right' dir='ltr'>
                        <Switch
                          checked={row.newProductFlag === 1 ? true : false}
                          color='primary'
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {row.storeId === 0 ? '-' : row.storeSku}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            dir='ltr'
            style={{ paddingRight: '41%' }}
            rowsPerPageOptions={[10, 25, 50]}
            component='div'
            count={this.state.dataToDisplay.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            nextIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            backIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage='מספר מוצרים בעמוד'
          />
        </Paper>
        <Dialog
          open={this.state.changeProductFlag}
          classes={{ paper: styles.dialogPaper }}
          aria-labelledby='form-dialog-title'
          maxWidth={'xs'}
          fullWidth={true}
          style={{ textAlign: 'end' }}
        >
          <DialogTitle id='form-dialog-title'>עריכת מוצר</DialogTitle>
          <DialogContent>
            <TextField
              disabled
              variant='outlined'
              inputProps={{ style: { direction: 'rtl' } }}
              autoFocus
              name='productName'
              label='שם המוצר'
              type='text'
              value={this.state.changeObject.productName}
              onChange={this.handleProductChange}
              margin='normal'
            />
            <br />
            <TextField
              disabled
              variant='outlined'
              inputProps={{ style: { direction: 'rtl' } }}
              autoFocus
              name='productBarcode'
              label='ברקוד'
              type='text'
              value={this.state.changeObject.productBarcode}
              onChange={this.handleProductChange}
              margin='normal'
            />
            <br />
            <TextField
              variant='outlined'
              inputProps={{ style: { direction: 'rtl' } }}
              autoFocus
              name='priceForFinalCustomer'
              label='מחיר לצרכן'
              type='text'
              value={this.state.changeObject.priceForFinalCustomer}
              onChange={this.handleProductChange}
              margin='normal'
            />
            <br />
            <TextField
              variant='outlined'
              inputProps={{ style: { direction: 'rtl' } }}
              autoFocus
              name='priceForStore'
              label='מחיר לרשת'
              type='text'
              value={this.state.changeObject.priceForStore}
              onChange={this.handleProductChange}
              margin='normal'
            />
            <br />

            <TextField
              variant='outlined'
              inputProps={{ style: { direction: 'rtl' } }}
              autoFocus
              name='storeSku'
              label='מק"ט לרשת'
              type='text'
              value={this.state.changeObject.storeSku}
              onChange={this.handleProductChange}
              margin='normal'
            />
            <br />
            <FormControlLabel
              control={
                <Switch
                  checked={
                    this.state.changeObject.saleFlag === 1 ? true : false
                  }
                  onChange={this.handleCheckbox}
                  name='saleFlag'
                  color='primary'
                />
              }
              label=':מוצר במבצע'
            />
            <br />
            {this.state.changeObject.saleFlag ? (
              <Grid container justify='flex-end'>
                <TextField
                  variant='outlined'
                  inputProps={{ style: { direction: 'rtl' } }}
                  autoFocus
                  name='salePrice'
                  label='מחיר מבצע'
                  type='text'
                  value={this.state.changeObject.salePrice}
                  onChange={this.handleProductChange}
                  margin='normal'
                />
                <br />
                <TextField
                  id='saleStartDate'
                  label='תאריך התחלת המבצע'
                  type='date'
                  onChange={this.changeDate}
                  value={
                    this.state.changeObject.saleFlag === 1
                      ? this.state.changeObject.saleStartDate
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  id='saleEndDate'
                  label='תאריך סיום המבצע'
                  type='date'
                  onChange={this.changeDate}
                  value={
                    this.state.changeObject.saleFlag === 1
                      ? this.state.changeObject.saleEndDate
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
            <FormControlLabel
              control={
                <Switch
                  checked={
                    this.state.changeObject.newProductFlag === 1 ? true : false
                  }
                  onChange={this.handleCheckbox}
                  name='newProductFlag'
                  color='primary'
                />
              }
              label=':מוצר חדש'
            />
            {this.state.changeObject.newProductFlag ? (
              <Grid container justify='space-around'>
                <TextField
                  id='newProductEndDate'
                  label='תאריך סיום מוצר חדש'
                  type='date'
                  onChange={this.changeDate}
                  value={
                    this.state.changeObject.newProductFlag === 1
                      ? this.state.changeObject.newProductEndDate
                      : ''
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            ) : null}
          </DialogContent>
          <DialogActions dir='rtl'>
            <Button
              onClick={(e) => this.setState({ changeProductFlag: false })}
              color='primary'
            >
              בטל
            </Button>
            <Button onClick={this.storeProductSave} color='primary'>
              שמור
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbars
          open={this.state.openSnack}
          closeHandler={this.closeSnackbar}
        />
      </Dialog>
    );
  }
}

export default StoreProduct;
