import React ,{Component} from "react"
import Container from "./Container";
import {Route, Switch } from "react-router-dom";
import Login from "./Login"
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import Protected from "./Protected"

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
    palette: {
      primary: {
        main:'#e61d25'
      },
      secondary: red,
    },
  });

class App extends Component{
  render(){
    return(
        <MuiThemeProvider theme={theme}>
            <Switch>
              <Route path = "/Login" component = {Login}/>
              <Protected  path ="/" component = {Container} />
            </Switch>
        </MuiThemeProvider>
    )
  }
}
export default App;