import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Db from './Db'
import Card from './Card'
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { yellow } from '@material-ui/core/colors';
import LoadingSpinner from './LoadingSpinner'
import Snackbars from './Snackbars'
const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    flexbasis:30,
    background:yellow
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    left: theme.spacing.unit * 2,
  },
});

class CenteredGrid extends Component {
  state = {
    storeImg:"",
    storeName:"",
    open:false,
    storeList : [],
    stilLoading:true,
    openSnack:{open:false,Message:'',type:0}
  }

  componentDidMount()
  {
    fetch(Db.StoreQuerry,
    {
      method:'GET',
      headers: new Headers(),
      credentials: 'include'
    }).then(res=>
      {
        if(200 === res.status)
        {
          return res.json()
        }
      }).then(data=>
        {
          if(undefined !==data)
          {
            this.setState({storeList:data,stilLoading:false})
          }
        })
  }

  openSnackBar=(message,type)=>
  {
      const prevState = this.state;
      this.setState({...prevState,openSnack:{
          open:true,
          Message:message,
          type:type
      }})
  }
  closeSnackbar = ()=> {
      const prev = this.state.openSnack;
      this.setState({openSnack:{...prev,open:false}});
  }


 handleChange = event =>
 {
   const {name, value} = event.target;
   this.setState({[name]:value})
 }
 handleNewStore = ()=>
 {
   this.setState({open:true})
 }
 handleStoreAdd = ()=>{
  let form = new FormData();
  form.append("file",this.state.storeImg[0]);
  form.append('name',this.state.storeName);
   fetch(Db.StoreQuerry,{
     method:'POST',
     headers: new Headers(),
     credentials: 'include',
     body:form
   }).then(res =>{
     if(200===res.status)
     {
      return res.json() 
     }
     else
     {
       this.openSnackBar('קרתה שגיאה,נסה שנית',2);
      return Promise.reject();
     }
   }).then(data=>{
    let newStoreList = this.state.storeList;
    let id = data.storeId;
    let imageName = this.state.storeImg[0]===undefined?"":this.state.storeImg[0].name;
    newStoreList.push({
      storeName:this.state.storeName,
      storeImg:imageName,
      storeId:id
     })
   this.setState({
     open:false,
     storeName:"",
     storeImg:"",
     storeList:newStoreList
   })
   this.openSnackBar('רשת נוספה בהצלחה',1);
   })
  
 }
 handleFile = event => {
   this.setState({storeImg:event.target.files})
 }
  render(){
    const { classes } = this.props;
    const fab = 
      {
        color: 'primary',
        className: classes.fab,
        icon: <AddIcon />,
      }

  return (
    this.state.stilLoading?
    <LoadingSpinner/>
    :
    <div className={classes.root}>
      <Grid container direction='row-reverse' spacing={24}>
        {this.state.storeList.map(store=>{
          return(
              <Grid item xs={12} sm ={6} m={4} lg={3} xl={2} key = {store.storeId}  >
                <Card className={classes.paper} store = {store} selectStore = {this.props.selectStore}></Card>
              </Grid>
          )
        })}
          <Fab className={fab.className} color={fab.color} onClick = {this.handleNewStore}>
            {fab.icon}
          </Fab>
        </Grid>
        <Dialog dir="rtl"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">הוסף חנות</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="storeName"
              label="שם הרשת"
              type="text"
              value = {this.state.storeName}
              onChange = {this.handleChange}
              InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
            />
            <br/>
              <TextField dir="ltr"
              margin="dense"
              name="storeImg"
              label="לוגו"
              type="file"
              onChange = {this.handleFile}
              InputLabelProps= {{style:{right:'0px',position:'absolute',textAlign:'right'}}}
            />  
            
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleStoreAdd} color="primary">
              הוסף
            </Button>
            <Button onClick={()=>{this.setState({open:false})}} color="primary">
              ביטול
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbars open={this.state.openSnack} closeHandler = {this.closeSnackbar}/>
    </div>
  );
}
}

CenteredGrid.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CenteredGrid);