class Db {
  constructor() {
    this.aws = 'https://backend.panel.simplybibo.com';
    this.local = 'http://localhost/SimplyBibo-server';
    this.David = 'http://davidra7.dyndns.org:8080/SimplyBibo-server';
    this.url = this.aws;
    this.SessionQuery = this.url + '/LoginServlet';
    this.LoginQuery = this.url + '/LoginServlet';
    this.StoreQuerry = this.url + '/StoreServlet';
    this.BranchQuerry = this.url + '/BranchServlet?store=';
    this.CategoryQuerry = this.url + '/CategoryServlet';
    this.productQuerry = this.url + '/ProductServlet';
    this.storeProductQuerry = this.url + '/ProductServlet?store=';
    this.ProductToStoreQuerry = this.url + '/ProductToStoreServlet';
    this.QuoteQuerry = this.url + '/QuotationServlet';
    this.OrdersQuerry = this.url + '/OrderServlet';
    this.recoverPassword = this.url + '/RecoverPasswordServlet';
    this.imagePrefix = 'https://panel.simplybibo.com/Products/';
    this.storeImagePrefix = 'https://panel.simplybibo.com/Stores/';
  }
}
export default new Db();
