import React ,{Component} from "react"
import { Redirect } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Db from "./Db";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { TextField } from "@material-ui/core";
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Dialog, DialogActions } from '@material-ui/core';
import StoreProduct from "./StoreProducts";
import StoreAppProps from './StoreAppProp';
import Snackbars from './Snackbars'
import LoadingSpinner from './LoadingSpinner'

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
    },
    textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
    table: {
      minWidth: 700,
    },
    button: {
        margin: theme.spacing.unit,
      },  
      rightIcon: {
        marginLeft: theme.spacing.unit,
      },
      dialogPaper:{
        maxHeight:'100vh',
          minHeight: '100vh'
      }
  });
class Store extends Component
{
    constructor(props)
    {
       
        super(props)
        this.state = {
            openSnack:{open:false,Message:'',type:0},
            editPref:false,
            edit:false,
            addBranch:false,
            newBranchInfo:{
                storeId:"",
                branchName:"",
                userName:"",
                mngrPhone:"",
                mngrEmail:""
            },
            editBranch:{},
            branches:[],
            storeId : props.store.storeId,
            redirect:false,
            storePerfrences:'',
            manageProduct:false,
            stilLoading:true
        }
    }
    componentDidMount()
    {
        if(this.props.store.storeId !== undefined)
        {
            fetch(Db.BranchQuerry+this.props.store.storeId,{
                method:'GET',
                headers:new Headers(),
                credentials: 'include',
            }).then(res =>
            {
                if(res.status === 200)
                {
                    return res.json()
                }
            }).then(data =>
            {
                this.setState({branches:data.Branches,storePerfrences:data.Pref,stilLoading:false})
            })
        }
    }
    //handle Store Deletion, send Delete to DB, redirect to Home.
    deleteStore =()=>
    {
        fetch(Db.StoreQuerry,{
            method: 'DELETE',
            headers:new Headers(),
            credentials: 'include',
             body:JSON.stringify({
                 storeId:this.state.storeId
             })
        }).then(res=>
            {
                if(res.status===200)
                {
                    this.openSnackBar('רשת נמחקה בהצלחה',1)
                    this.setState ({redirect:true})
                }
                else {
                    this.openSnackBar('!לא ניתן למחוק את הרשת',2)
                }
            })
    }
    //handle input for new Branch
    handleChnage =(event) =>
    {
        const {name ,value} = event.target
        this.setState({newBranchInfo:
        {
            ...this.state.newBranchInfo,
            [name]:value
        }})
    }

        //handle Branch addition to specific chain
    addNewBrach=()=>
    {
        let newBranch = this.state.newBranchInfo
        newBranch = {...newBranch,storeId:this.state.storeId}
        fetch(Db.BranchQuerry,{
            method:'POST',
            headers:new Headers(),
            credentials: 'include',
            body:JSON.stringify(newBranch)
        }).then(res=>
        {
            if(res.status === 200)
            {
                return res.json()
            }
            else
            {
                this.openSnackBar('קרתה שגיאה,נסה שנית',2)
                return Promise.reject();
            }
        }).then(data=>{

            const updateBranches= this.state.branches
                updateBranches.push({branchId:data.branchId,storeId:this.state.storeId,...this.state.newBranchInfo})
                this.setState({
                    addBranch:false,
                    branches:updateBranches,
                    newBranchInfo:{
                        storeId:"",
                        branchName:"",
                        userName:"",
                        mngrPhone:"",
                        mngrEmail:""
                    }
                })
                this.openSnackBar('סניף נוצר בהצלחה',1)
        })
    }

    openSnackBar=(message,type)=>
    {
        const prevState = this.state;
        this.setState({...prevState,openSnack:{
            open:true,
            Message:message,
            type:type
        }})
    }
    closeSnackbar = ()=> {
        const prev = this.state.openSnack;
        this.setState({openSnack:{...prev,open:false}});
    }

    handlePrefrencesSubmit = ()=>
    {
        const newPerf = {...this.state.editPrefData,storeId:this.state.storeId};
        const prevState = this.state;
        fetch(Db.StoreQuerry,{
            method:'PUT',
            headers:new Headers(),
            credentials: 'include',
            body:JSON.stringify(newPerf)
        }).then(res=>{
            if(200 === res.status){
                this.setState({...prevState,storePerfrences:this.state.editPrefData,editPref:false})
                this.openSnackBar('שינוי נשמר בהצלחה',1);
            }
            else{
                this.setState({editPref:false})
                this.openSnackBar('קרתה שגיאה,נסה שנית',2);
            }
        })
    }
    handlePrefrencesChange = (name, value)=>
    {
        let prevState = this.state.editPrefData;
        this.setState({editPrefData:{...prevState,[name]:value}})
    }
    closeProducts = ()=>{
        this.setState({manageProduct:false});
    }
    removeBranch = (row)=>
    {
        fetch(Db.BranchQuerry,{
            method: 'DELETE',
            headers:new Headers(),
            credentials: 'include',
             body:JSON.stringify({
                 branchId:row
             })
        }).then(res=>
            {
                if(res.status===200)
                {
                    let updateBranches= this.state.branches
                    updateBranches = updateBranches.filter(branch=>branch.branchId !== row)
                    this.setState({branches:updateBranches})
                    this.openSnackBar('סניף נמחק בהצלחה',1);
                }
                else{
                    this.openSnackBar('קרתה שגיאה,נסה שנית',2);
                }
            })
    }

    handleBranchEdit = (event=>{
        const {name,value} = event.target;
        const prevState = this.state.editBranch;
        this.setState({...this.state,editBranch:{...prevState,[name]:value}})
    })

    submitBranch = ()=>{
        let updatedBranches = this.state.branches;
        fetch(Db.BranchQuerry,{
            method:'PUT',
            header:new Headers(),
            credentials: 'include',
            body:JSON.stringify(this.state.editBranch)
        }).then(res =>{
            if (200===res.status){
                let index = updatedBranches.findIndex((row)=>this.state.editBranch.branchId===row.branchId)
                updatedBranches[index] = this.state.editBranch;
                this.setState({edit:false,editBranch:{},branches:updatedBranches})
                this.openSnackBar('פרטי הסניף שונו בהצלחה',1);
            }
            else{
                this.openSnackBar('קרתה שגיאה,נסה שנית',2);
            }
        })
    }
    handleEdit = (e,row)=>{
        if(e.target.nodeName ==="TD")
        this.setState({edit:true,editBranch:row})
    }
    sendPassword = Username=>{
        fetch(Db.recoverPassword,{
                method:'POST',
                header:new Headers(),
                credentials:'include',
                body:JSON.stringify({
                    Username
                })
            }).then(res=>{
                if(200===res.status)
                {
                    this.openSnackBar('נשלחה סיסמה חדשה',1);
                }
                else{
                    this.openSnackBar('לא הצלחנו לייצר סיסמה',2);
                }
            })
        }

    render()
    {
        const customColumnStyle = { width: 12};
        return (
            this.state.redirect || this.state.storeId === undefined?
            <Redirect
            to = {{
                pathname:"/",
                state:{
                    from:this.props.location
                }
            }}
            />
            :
            this.state.stilLoading?
            <LoadingSpinner/>
            :
            <div style = {{position:'relative'}}>
                <img src = {`${Db.storeImagePrefix}${this.props.store.storeImg} `}
                    style = {{height:100,width:150}}
                    alt={this.props.store.storeImg}
                ></img>
                <br/>
                {/* <Typography >{this.props.store.storeName}</Typography> */}
                <Button color = "primary" variant="contained" onClick={e=>this.setState({editPref:true,editPrefData:this.state.storePerfrences})} style = {{marginBottom:15,marginRight:10}}>מאפייני רשת</Button>
                <Button 
                style={{marginBottom:15, marginRight:10}}
                onClick = {()=>this.setState({manageProduct:true})}
                color = "primary"
                variant = "contained"
                >
                ניהול מוצרים</Button>
                <Button style={{marginBottom:15}}
                    onClick = {()=>{const temp = this.state.addBranch;this.setState({addBranch:!temp})}}
                    color="primary"
                    variant="contained"
                    >
                הוסף סניף
                <AddIcon />
                </Button>
                
                <Button style={{left:24,position:'absolute',marginBottom:15}}
                    onClick = {this.deleteStore}
                    color="primary"
                    variant="contained"
                    >
                מחק רשת
                <DeleteIcon className={styles.rightIcon} />
                </Button>

                <br/>
                <Paper className={styles.root}>
                <Table className={styles.table} dir="rtl">
                    <TableHead>
                    <TableRow>
                        <TableCell align="right">שם הסניף</TableCell>
                        <TableCell align="right">שם משתמש</TableCell>
                        <TableCell align="right">מס' טלפון</TableCell>
                        <TableCell align="right">Email</TableCell>
                    <TableCell align="right" style={customColumnStyle}></TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.branches.map(row => (
                        <TableRow key={row.branchId} hover onClick= {(e)=>this.handleEdit(e,row)}>
                            <TableCell align="right"><div>{row.branchName}</div></TableCell>
                            <TableCell align="right">{row.userName}</TableCell>
                            <TableCell align="right">{row.mngrPhone}</TableCell>
                            <TableCell align="right">{row.mngrEmail}</TableCell>
                            <TableCell align="left" style={customColumnStyle}>
                                <IconButton className={styles.button} aria-label="Delete" onClick={this.removeBranch.bind(this,row.branchId)} style={{visibility:'hidden'}}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                            <TableCell align="left">
                            <Button
                                onClick = {this.sendPassword.bind(this, row.userName)}
                                color="primary"
                                variant="contained"
                                    >
                                חידוש סיסמא
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                    <Dialog
                        open= {this.state.edit}
                        //  dir="rtl"
                        maxWidth={"sm"}>
                        
                        <DialogTitle id="form-dialog-title" dir = "rtl">ערוך פרטי סניף</DialogTitle>
                        <DialogContent style = {{alignContent:'center'}}>
                            <TextField
                                inputProps = {{style:{direction:"rtl"}}}
                                className = {styles.textField}
                                label="שם הסניף"
                                value = {this.state.editBranch.branchName}
                                name = "branchName"
                                // InputLabelProps= {{style:{right:'0px',position:'absolute',textAlign:'right'}}}
                                onChange = {this.handleBranchEdit}
                                variant="outlined"
                                margin ="normal">
                            </TextField>
                            <br/>
                            <TextField
                                inputProps = {{style:{direction:"rtl"}}}
                                label="שם משתמש"
                                value = {this.state.editBranch.userName}
                                name = "userName"
                                // InputLabelProps= {{style:{right:'-20px',position:'absolute',textAlign:'right'}}}
                                onChange = {this.handleBranchEdit}
                                variant="outlined"
                                margin ="normal">
                            </TextField>
                            <br/>
                            <TextField
                                inputProps = {{style:{direction:"rtl"}}}
                                label="מס' טלפון"
                                value = {this.state.editBranch.mngrPhone}
                                name = "mngrPhone"
                                // InputLabelProps= {{style:{right:'0px',position:'absolute',textAlign:'right'}}}
                                onChange = {this.handleBranchEdit}
                                variant="outlined"
                                margin ="normal">
                            </TextField>
                            <br/>
                            <TextField
                                inputProps = {{style:{direction:"rtl"}}}
                                label="Email"
                                value = {this.state.editBranch.mngrEmail}
                                name = "mngrEmail"
                                // InputLabelProps= {{style:{right:'0px',position:'absolute',textAlign:'right'}}}
                                onChange = {this.handleBranchEdit}
                                variant="outlined"
                                margin ="normal">
                                
                            </TextField>
                            <br/>
                        </DialogContent>
                        <DialogActions >
                        <Button
                         onClick={()=>{this.setState({edit:false})}} color="primary"
                         >בטל</Button>
                        <Button color="primary" onClick={this.submitBranch}>שמור שינויים</Button>
                        </DialogActions>
                    </Dialog>
                    {
                    this.state.addBranch?
                        <TableRow key={1}>
                            <TableCell align="right" style={{paddingRight:20,maxWidth:130}}>
                            <TextField
                                maxLength="10"
                                name="branchName"
                                value={this.state.newBranchInfo.branchName}
                                label = "שם הסניף"
                                InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                                onChange={this.handleChnage}
                            >
                            </TextField>
                            </TableCell>
                            <TableCell align="right" style={{paddingRight:20,maxWidth:130}}>
                            <TextField
                                name="userName"
                                onChange={this.handleChnage}
                                label = "שם משתמש"
                                InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                                value={this.state.newBranchInfo.userName}>
                                </TextField>
                            </TableCell>
                            <TableCell align="right" style={{paddingRight:20,maxWidth:130}}>
                            <TextField
                                name="mngrPhone"
                                onChange={this.handleChnage}
                                label = "מס' טלפון"
                                InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                                value={this.state.newBranchInfo.mngrPhone}>
                                
                                </TextField>
                            </TableCell>
                            <TableCell align="right" style={{paddingRight:20,maxWidth:130}}>
                            <TextField
                                type="email"
                                name="mngrEmail"
                                onChange={this.handleChnage}
                                label = "Email"
                                InputLabelProps= {{style:{right:'0px',position:'absolute'}}}
                                value={this.state.newBranchInfo.mngrEmail}>
                                </TextField>
                            </TableCell>
                            <TableCell style={customColumnStyle}></TableCell>
                            <TableCell align="left" style={customColumnStyle}>
                                <IconButton className={styles.button} aria-label="Save" onClick={this.addNewBrach}>
                                    <SaveIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    :
                        null
                }
                    </TableBody>
                </Table>
                </Paper>
                <Dialog open = {this.state.editPref} dir = "rtl">
                <DialogTitle id="form-dialog-title" >מאפייני רשת לאפליקציה</DialogTitle>  
                    <StoreAppProps Perfrences = {this.state.editPrefData} handler = {this.handlePrefrencesChange}/>
                <DialogActions>
                    <Button color = "primary" onClick={e=>this.setState({editPref:false})}>סגור</Button>
                    <Button color = "primary" onClick={this.handlePrefrencesSubmit}>שמור שינויים</Button>
                </DialogActions>
                </Dialog>
                
                <StoreProduct open={this.state.manageProduct} close = {this.closeProducts} storeId = {this.props.store.storeId} name = {this.props.store.storeName}/>
                <Snackbars open={this.state.openSnack} closeHandler = {this.closeSnackbar}/>
            </div>
        )
    }
}

export default Store