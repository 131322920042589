import React,{Component} from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {Typography } from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
class StoreAppProps extends Component
{
state={
    storePerfrences:this.props.Perfrences
}

onChangeHandler = (e)=>{
  const {name,value,checked} = e.target;
  if(""===value && undefined !== checked){
    this.props.handler(name, checked?1:0);
  }
  else{
    this.props.handler(name,value);
  }
}
render()
{
  let checked = false;
  if(1===this.props.Perfrences.storeShowTotalPrice)
  checked  = true;
    return (
        <DialogContent style = {{position:'relative'}}>
        <br/><br/>

        <Typography style = {{display:'inline'}}>מחיר מינימום להזמנה:</Typography>

            <TextField
            style = {{display:'inline'}}
            value = {this.props.Perfrences.storeMinToOrder}
            name = "storeMinToOrder"
            onChange = {this.onChangeHandler}
            >מינימום הזמנה:</TextField>

            <br/>
            <Typography  style ={{display:'inline'}}>
            מחיר חנות להצגה :
            </Typography>
            <Select 
            value={this.props.Perfrences.storePriceToShow}
            onChange={this.onChangeHandler}
            name = "storePriceToShow"
            displayEmpty
            autoWidth
            style = {{minWidth:150}}
          >
            <MenuItem value={0}>
              <em>ללא</em>
            </MenuItem>
            <MenuItem value={1}>
              <em>מחיר לרשת</em>
            </MenuItem>                    
            <MenuItem value={2}>
              <em>מחיר לצרכן</em>
            </MenuItem>
            </Select>

            <br/>
              <Typography>
              הצגת מחיר כולל :
           
           </Typography>
            <Typography component="div" dir ="ltr" style = {{position:'fixed'}}>
            <Typography >
                כן
           <Switch
            checked={checked}
            onChange = {this.onChangeHandler}
            color = "primary"
            name = "storeShowTotalPrice"
            />
            לא
            </Typography>
            </Typography>
            <br/>
        </DialogContent>
    )

}


}

export default StoreAppProps;